// import Link from 'next/link'
import { NextPage } from 'next'
import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import LoginContainer from '../components/LoginContainer'

const Container = styled.div`
  padding-bottom: 20px;
`

const Login: NextPage = () => {
  return (
    <Layout titleText="Login" hideNavBar hasPadding>
      <div>
        <Container>
          <LoginContainer />
        </Container>
        {/*<Container>*/}
        {/*  <Link href="/password-recovery">*/}
        {/*    <a>Forgot your password?</a>*/}
        {/*  </Link>*/}
        {/*</Container>*/}
      </div>
    </Layout>
  )
}

export default Login
