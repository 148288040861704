import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { AuthCookie } from 'utils/auth'
import { useOktaAuth } from '@okta/okta-react'
import cookie from 'js-cookie'
import { OktaLogin } from './utils/OktaLogin'

const LoginContainer = () => {
  const router = useRouter()
  const { authState, oktaAuth } = useOktaAuth()
  const pendingLogin = React.useRef(false)

  useEffect(() => {
    // clean up the email/password access token in cookie
    Object.values(AuthCookie).map(value => cookie.remove(value))
  }, [])

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const redirectPath = router?.query?.redirect?.toString()
      pendingLogin.current = false
      router.replace(redirectPath || '/')
    }
  }, [authState, oktaAuth, router])

  return <OktaLogin />
}

export default LoginContainer
