import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

const StyledCard = styled(Card)`
  && {
    font-family: Greycliff CF !important;
  }
  min-width: 400px;
  width: 30%;
  margin: 40px auto;
  height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.lightGrey1};
`
const StyledButton = styled(Button)`
  && {
    font-family: 'Greycliff CF';
    text-transform: none;
    background: ${theme.colors.midGreen};
    color: ${theme.colors.white};
    :hover {
      background: ${theme.colors.midGreen};
      color: ${theme.colors.white};
    }
  }
`
const Title = styled(Typography)`
  && {
    font-size: 18px;
    font-family: 'Greycliff CF';
  }
`
export const OktaLogin = () => {
  const pendingLogin = React.useRef(false)
  const { oktaAuth } = useOktaAuth()
  const handleLogin = useCallback(async () => {
    if (pendingLogin.current) {
      return
    }

    pendingLogin.current = true

    await oktaAuth.signInWithRedirect()
  }, [oktaAuth])

  return (
    <StyledCard>
      <CardContent>
        <Title gutterBottom>Please login with Okta</Title>
      </CardContent>
      <CardActions>
        <StyledButton onClick={handleLogin} variant="contained" size="large">
          Log in with Okta
        </StyledButton>
      </CardActions>
    </StyledCard>
  )
}
